.animationModal {
  position: fixed;
  width: 330px;
  height: 180px;
  /* top: 0;
  left: 0; */
  left: 40px;
  bottom: 0;
  display:block;
  margin: auto;
  border-radius: 5px;
  background: #000000a6;
  overflow: visible;
  z-index: 9999;
  box-shadow: inset 0 0 1px 0 #000;
}

.animationModal-title {
  width: 100%;
  /* height: 50px; */
  /* line-height: 50px; */
  /* padding: 5px 10px; */
  color:white;
  text-align:center;
}

.animationModal-content {
  width: 100%;
  height: 150px;
  /* padding: 0 10px; */
  color:white;
  text-align:center;
}

.animationModal-operator {
  /* width: 100%;
  height: 50px; */

}

.animationModal-operator-close, .animationModal-operator-confirm {
  /* width: 50%; */
  border: none;
  outline: none;
  /* height: 50px; */
  top:0;
  right:0;
  position:absolute;
  /* line-height: 50px; */
  opacity: 1;
  color: #fff;
  background: rgb(247, 32, 32);
  cursor: pointer;
}

.animationModal-operator-close:active, .animationModal-operator-confirm:active {
  opacity: .6;
  transition: opacity .3s;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: #000;
  opacity: .6; */
  z-index: 9998;
}

.animationModal-enter {
  transform: scale(0);
}

.animationModal-enter-active {
  transform: scale(1.1);
  transition: all .2s linear;
}

.animationModal-enter-end {
  transform: scale(1);
  transition: all .1s linear;
}

.animationModal-leave {
  transform: scale(1);
}

.animationModal-leave-active {
  transform: scale(1.1);
  transition: all .1s linear;
}

.animationModal-leave-end {
  transform: scale(0);
  transition: all .2s linear;
}
