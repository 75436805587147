.flexible-modal {
  position: fixed;
  z-index: 9999 !important; 
  border: 2px solid #000000a6;
  background: white;
}
.flexible-modal-mask {
  position: fixed;
  height: 100%; 
  background: rgba(55, 55, 55, 0.6);
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 9998;
}
.flexible-modal-resizer {
  position:absolute;
  right:0;
  bottom:0;
  cursor:se-resize;
  margin:5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area{
  /* background: #000000a6; */
  height: 30px !important;
  position:absolute;
  right:0;
  top:0;
  cursor:move;
}

.flexible-modal-title{
  color:white;
  background-color: #000000a6;
  padding:5px;
}

.flexible-modal-funcBar {
  border-bottom: 1px solid #333;
}

.flexible-modal-clsBtn {
    /* width: 50%; */
    border: none;
    outline: none;
    /* height: 50px; */
    top:0;
    right:0;
    position:absolute;
    /* line-height: 50px; */
    opacity: 1;
    color: #fff;
    background: rgb(247, 32, 32);
    cursor: pointer;
    z-index: 10000;
  }
  
  .flexible-modal-clsBtn:active {
    opacity: .6;
    transition: opacity .3s;
  }