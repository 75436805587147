.rstcustom__row {
  word-break: break-all;
  white-space: normal;
  display: block;
  /* width:100%; */
}

.rstcustom__rowContents {
  height: auto;
}

.rstcustom__rowLabel {
  width:100%;
}